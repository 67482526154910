import { Button, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWeb3Context } from "../common/hooks/useWeb3Context";
import Landing from "../components/UI/ReferralLink/Landing";

interface IButtonProps {
  invitationError?: string | null;
  beenInvited?: boolean;
}

const ButtonProps: React.FC<IButtonProps> = (props: IButtonProps) => {
  const { invitationError, beenInvited } = props;
  const { connect, connected, disconnect } = useWeb3Context();
  const navigate = useNavigate();
  const theme = useTheme();
  const [hasOngoingStakes, setHasOngoingStakes] = useState<boolean>(false);

  return (
    <Button
      variant="contained"
      sx={{
        color: "#000",
        backgroundColor: theme.palette.text.primary,
        "&:hover": {
          color: "#FFF",
          backgroundColor: theme.palette.secondary.main,
        },
      }}
      onClick={(e) => {
        e.preventDefault();
        if (!connected) {
          console.log("1");
          connect();
        } else if (connected && invitationError) {
          console.log("2");
          disconnect();
          navigate("/");
        } else if (connected && hasOngoingStakes) {
          navigate("/staking-dashboard");
        } else if (connected && !invitationError && beenInvited) {
          console.log("3");
          return false;
        } else if (connected && !invitationError && !beenInvited) {
          console.log("4");
          navigate("summary");
        }
      }}
    >
      {connected && !invitationError && !beenInvited && "Join Us"}
      {connected && invitationError && "Disconnect Wallet"}
      {!connected && "Connect Wallet"}
    </Button>
  );
};

const LandingPage = () => {
  let tempInvitationError =
    "Wallet address does not match address specified on referral link.";
  const { address, connected } = useWeb3Context();
  const [expired, setExpired] = useState<boolean>(false);
  const [invitationError, setinvitationError] = useState<string | null>(null);

  return (
    <>
      {!connected && (
        <Landing
          title={"Welcome"}
          divider={true}
          button={<ButtonProps />}
          subDesciption={"Connect your wallet to access dashboard"}
        />
      )}
      {connected && expired && (
        <Landing
          title={"Welcome"}
          divider={true}
          description={
            "Oh no, this link is invalid or has expired. Contact your referrer for a new link. Referral links are valid for 7 days only."
          }
        />
      )}
      {/* TODO: check if user has invitation code ? */}
      {connected && !expired && (
        <Landing
          title={"Invitation to Join"}
          divider={true}
          description={
            invitationError
              ? "Please connect wallet with the following address:"
              : "You are invited to join UnitedDAO! Please confirm that this is your wallet address:"
          }
          button={
            <ButtonProps
              invitationError={invitationError}
              beenInvited={false}
            />
          }
          textDisplay={address}
          subDesciption={
            "Disconnect and re-connect wallet with that matches the address above."
          }
          invitationError={invitationError}
        />
      )}
    </>
  );
};

export default LandingPage;
