import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  useTheme,
  Divider,
  TextField,
  InputAdornment,
  Snackbar,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";

interface ShareSocialProp {
  open: boolean;
  toggleClose: () => void;
}

const ShareSocial: React.FC<ShareSocialProp> = (props: ShareSocialProp) => {
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);
  const handleCopylink = () => {
    setOpen(true);

    navigator.clipboard.writeText("test");
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.toggleClose}
        onBackdropClick={props.toggleClose}
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "calc(100% - 48px)",
            width: { xs: "100%", lg: "50%" },
            height: "calc(50% - 48px)",
            borderRadius: "12px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>Share your code</DialogTitle>
        <Divider
          light
          sx={{
            backgroundColor: theme.palette.text.primary,
          }}
        />
        <DialogContent sx={{ maxHeight: "calc(50% - 20px)" }}>
          <DialogContentText>
            <TextField
              fullWidth
              id="referralLink"
              name="referralLink"
              disabled
              value={"https://client.uniteddao.io/invite?code=2548asi1212331"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      aria-label="copy-link"
                      sx={{
                        "& .MuiButton-text": {
                          color: theme.palette.text.primary,
                        },
                      }}
                      onClick={handleCopylink}
                    >
                      <Box
                        component="span"
                        sx={{
                          color: theme.palette.text.primary,
                          fontSize: {
                            xs: "10px",
                            lg: "16px",
                          },
                        }}
                      >
                        <ContentCopyIcon
                          sx={{
                            color: theme.palette.text.primary,
                            fontSize: {
                              xs: "10px",
                              lg: "16px",
                            },
                          }}
                        />
                        Copy Link
                      </Box>
                    </Button>
                  </InputAdornment>
                ),
              }}
              sx={{
                background: "#374575",
                borderRadius: "8px",
                "& .MuiInputLabel-root": {
                  color: theme.palette.text.secondary,
                },
                "& .Mui-disabled": {
                  color: theme.palette.text.secondary,
                  wordWrap: "break-word",
                  fontSize: {
                    xs: "10px",
                    lg: "16px",
                  },
                  WebkitTextFillColor:
                    theme.palette.text.secondary + "!important",
                },
              }}
            />
            <Snackbar
              message="Copied to clibboard"
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={6000}
              onClose={() => setOpen(false)}
              open={open}
            />
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              padding: "20px 0 0 0",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "12px",
                textDecoration: "none",
                color: theme.palette.text.secondary,
              }}
            >
              Share to your friend by using these
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              p: {
                md: 2,
                lg: 1,
              },
            }}
          >
            <WhatsappShareButton
              title="My Referral Link"
              url="https://client.uniteddao.io/invite?code=2548asi1212331"
            >
              <WhatsAppIcon sx={{ color: theme.palette.text.primary }} />
            </WhatsappShareButton>
            <Typography
              variant="subtitle1"
              color="initial"
              sx={{ color: theme.palette.text.primary, textAlign: "center" }}
            >
              Whatsapp
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              p: {
                md: 2,
                lg: 1,
              },
            }}
          >
            <TelegramShareButton
              title="My Referral Link"
              url="https://client.uniteddao.io/invite?code=2548asi1212331"
            >
              <TelegramIcon sx={{ color: theme.palette.text.primary }} />
            </TelegramShareButton>
            <Typography
              variant="subtitle1"
              color="initial"
              sx={{ color: theme.palette.text.primary, textAlign: "center" }}
            >
              Telegram
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              p: {
                md: 2,
                lg: 1,
              },
            }}
          >
            <TwitterShareButton
              title="My Referral Link"
              url="https://client.uniteddao.io/invite?code=2548asi1212331"
            >
              <TwitterIcon sx={{ color: theme.palette.text.primary }} />
            </TwitterShareButton>
            <Typography
              variant="subtitle1"
              color="initial"
              sx={{ color: theme.palette.text.primary, textAlign: "center" }}
            >
              Twitter
            </Typography>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShareSocial;
