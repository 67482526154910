import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { formatNumber } from "../common/helpers/NumberHandler";
import { useWeb3Context } from "../common/hooks/useWeb3Context";
import MyWallet from "../components/UI/MyWallet";

interface StatFieldProps {
  label: string;
  value: string;
  sxLabel?: SxProps<Theme>;
  sxValue?: SxProps<Theme>;
}

const StatField: React.FC<StatFieldProps> = ({
  label,
  value,
  sxLabel,
  sxValue,
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      pb: 1,
    }}
  >
    <Typography
      variant="body1"
      color="textSecondary"
      sx={{ ...sxLabel, maxWidth: { lg: "unset", xs: "48%" } }}
    >
      {label}
    </Typography>
    <Typography
      variant="body1"
      color="textSecondary"
      sx={{
        ...sxValue,
        maxWidth: { lg: "unset", xs: "48%" },
        wordBreak: "break-all",
      }}
      textAlign="right"
    >
      {value}
    </Typography>
  </Box>
);

const StakingDashboard: React.FC = () => {
  let nextRebaseTime: string = "5hrs, 13 mins to next release";

  const [unstakeMode, setUnstakeMode] = useState<boolean>(false);
  const [utdApproved, setUtdApproved] = useState<boolean>(false);
  const [unstakeSuccess, setUnstakeSuccess] = useState<boolean>(false);

  const { address, connected, disconnect, provider } = useWeb3Context();
  const [myWalletOpen, setMyWalletOpen] = useState<boolean>(false);

  const handleApprove = () => {
    setUtdApproved(true);
  };

  const handleUnstake = () => {
    setUnstakeSuccess(true);
  };

  const handleGoToDashboard = () => {
    setUnstakeMode(false);
    setUtdApproved(false);
    setUnstakeSuccess(false);
  };

  return (
    <Box
      sx={{
        maxHeight: "calc(100% - 60px)",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          borderRadius: 2,
          padding: 3,
          maxWidth: 1000,
          width: { md: "60%", xs: "100%" },
          height: { lg: "calc(50% - 20px)", xs: "fit-content" },
          marginBottom: 3,
        }}
      >
        {!unstakeSuccess ? (
          <>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h5" color="textPrimary">
                Single Stake
              </Typography>
              <Typography variant="caption" color="textPrimary">
                {nextRebaseTime}
              </Typography>
            </Box>

            <StatField label="Start Date of Staking" value="20-02-2022" />
            <StatField
              label="Staked Amount"
              value={`${formatNumber(20000)} sUTD`}
            />
            <StatField label="Next Reward Yield" value="0.2390% = 0.00 sUTD" />

            <StatField
              label="Referrer's Wallet Address"
              value="0x37d7d1dd5b6f9c864d9db560d72a247c178ae86b"
              sxLabel={{ color: (theme) => theme.palette.text.primary }}
              sxValue={{
                color: (theme) => theme.palette.text.primary,
                fontStyle: "italic",
              }}
            />

            <Divider
              sx={{
                my: 2,
                backgroundColor: (theme) => theme.palette.text.primary,
              }}
            />

            <StatField
              label="Rewards Till Date"
              value={`${formatNumber(300)} sUTD`}
            />
            <StatField
              label="Total Balance"
              value={`${formatNumber(20300)} sUTD`}
            />

            {!unstakeMode ? (
              <Box sx={{ width: "100%", textAlign: "center", my: 5 }}>
                <Button
                  onClick={() => setUnstakeMode(true)}
                  sx={{
                    px: 5,
                    backgroundColor: (theme) => theme.palette.text.primary,
                    ":hover": {
                      backgroundColor: (theme) =>
                        `${theme.palette.text.primary}80`,
                    },
                  }}
                >
                  Unstake
                </Button>
              </Box>
            ) : (
              <Box>
                <Box sx={{ width: "100%" }}>
                  <Typography variant="h5" textAlign="center" sx={{ mb: 2 }}>
                    Unstake
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { lg: "row", xs: "column" },
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <TextField
                      defaultValue={0}
                      sx={{
                        width: { lg: "80%", xs: "100%" },
                        backgroundColor: (theme) =>
                          theme.palette.secondary.main,
                        borderRadius: 3,
                        "& fieldset": { border: "none" },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              onClick={() => null}
                              sx={{
                                color: (theme) => theme.palette.text.secondary,
                              }}
                            >
                              Max. amount
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography
                      sx={{ display: { lg: "none", xs: "block" }, mb: 3 }}
                    >
                      First time unstaking UTD? Approve UnitedDao to make
                      transactions.
                    </Typography>
                    <Button
                      onClick={() =>
                        !utdApproved ? handleApprove() : handleUnstake()
                      }
                      sx={{
                        height: 56,
                        width: { lg: "18%", xs: "60%" },
                        mb: { lg: 0, xs: 2 },
                        borderRadius: 3,
                        backgroundColor: (theme) => theme.palette.text.primary,
                        ":hover": {
                          backgroundColor: (theme) =>
                            `${theme.palette.text.primary}80`,
                        },
                      }}
                    >
                      {!utdApproved ? "Approve" : "Unstake"}
                    </Button>
                  </Box>
                  <Typography
                    sx={{ display: { lg: "block", xs: "none" }, mb: 3 }}
                  >
                    First time unstaking UTD? Approve UnitedDao to make
                    transactions.
                  </Typography>
                  <StatField
                    label="Your UTD Balance"
                    value={`${formatNumber(500)} UTD`}
                  />
                </Box>
              </Box>
            )}
          </>
        ) : (
          <Box sx={{ px: { lg: 16, xs: 0 } }}>
            <Typography textAlign="center" sx={{ mb: 3 }}>
              Successfully Unstaked!
            </Typography>
            <StatField
              label="Amount Unstaked"
              value={`${formatNumber(22000)} sUTD`}
            />
            <StatField
              label="Total Staked Balance"
              value={`${formatNumber(1000)} sUTD`}
            />
            <StatField
              label="Your UTD Balance"
              value={`${formatNumber(22500)} UTD`}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { lg: "row", xs: "column" },
                justifyContent: "center",
                alignItems: "center",
                py: 3,
              }}
            >
              <Button
                onClick={handleGoToDashboard}
                variant="outlined"
                sx={{
                  px: 3,
                  width: { lg: "fit-content", xs: "80%" },
                  mr: { lg: 3, xs: 0 },
                  mb: { lg: 0, xs: 2 },
                  borderColor: (theme) => theme.palette.text.primary,
                  color: (theme) => theme.palette.text.primary,
                  ":hover": {
                    backgroundColor: (theme) =>
                      `${theme.palette.text.primary}80`,
                  },
                }}
              >
                Go to Dashboard
              </Button>
              <Button
                onClick={() => setMyWalletOpen(true)}
                sx={{
                  px: 3,
                  width: { lg: "fit-content", xs: "80%" },
                  backgroundColor: (theme) => theme.palette.text.primary,
                  ":hover": {
                    backgroundColor: (theme) =>
                      `${theme.palette.text.primary}80`,
                  },
                }}
              >
                View Wallet
              </Button>
            </Box>
          </Box>
        )}
      </Card>
      <MyWallet
        user={{
          balance: 0,
          address: address,
          disconnect: disconnect,
          provider: provider,
          connected: connected,
        }}
        myWalletOpen={myWalletOpen}
        setMyWalletOpen={setMyWalletOpen}
      />
    </Box>
  );
};

export default StakingDashboard;
