import React from "react";
import { Box, Card, SxProps, Theme, Typography } from "@mui/material";

interface StatCardProps {
  main: number | string;
  units?: string;
  label?: string;
  sx?: SxProps<Theme>;
}

const StatCard: React.FC<StatCardProps> = ({ main, units, label, sx }) => {
  return (
    <Card
      sx={{
        backgroundColor: (theme) => theme.palette.secondary.main,
        height: "100%",
        boxShadow: "none",
        px: 2,
        py: 1,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        ...sx,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: {
              lg: "2rem",
              xs: "1.2rem",
            },
          }}
        >
          {main}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            ml: 1,
            fontSize: {
              lg: "1rem",
              xs: "0.5rem",
            },
          }}
        >
          {units}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: {
            lg: "1rem",
            xs: "0.5rem",
          },
        }}
      >
        {label}
      </Typography>
    </Card>
  );
};

export default StatCard;
