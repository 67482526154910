import React from "react";
import {
  Box,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from "@mui/material";
import partner from "../../../assets/ally-ranks/partner.svg";
import ambassador from "../../../assets/ally-ranks/ambassador.svg";
import associate from "../../../assets/ally-ranks/associate.svg";
import StatCard from "./StatCard";
import { formatNumber } from "../../../common/helpers/NumberHandler";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

interface AllyRank {
  key: string;
  label: string;
  image: string;
  minimum: number;
}

const allyRankMap: AllyRank[] = [
  {
    key: "partner",
    label: "Partner",
    image: partner,
    minimum: 25,
  },
  {
    key: "associate",
    label: "Associate",
    image: ambassador,
    minimum: 10,
  },
  {
    key: "ambassador",
    label: "Ambassador",
    image: associate,
    minimum: 0,
  },
];

const UserOverview: React.FC = () => {
  const allyRankValue = 20;
  const { key, label, image } =
    allyRankMap.find(({ minimum }) => allyRankValue >= minimum) ||
    allyRankMap[2];

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img src={image} alt={key} height="100%" width={110} />
        <Box
          sx={{
            width: {
              lg: 380,
              xs: "fit-content",
            },
            ml: 3,
            mr: 3,
          }}
        >
          <Typography variant="body2">Ally Rank</Typography>
          <Typography variant="h5">{label}</Typography>
          <Box sx={{ width: "100%", display: "flex" }}>
            <Typography variant="caption" sx={{ width: "40%" }}>
              0
            </Typography>
            <Typography variant="caption" sx={{ width: "60%" }}>
              10
            </Typography>
            <Typography variant="caption">25</Typography>
          </Box>
          <BorderLinearProgress
            variant="determinate"
            value={(allyRankValue / 25) * 100}
          />
          <Typography variant="body2" sx={{ mt: 1, fontSize: "0.7rem" }}>
            15.03 aUTD pledged, 6.97 aUTD away from becoming a Partner!
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          mt: {
            lg: 0,
            xs: 2,
          },
          width: {
            lg: "calc(100% - 380px)",
            xs: "100%",
          },
          justifyContent: {
            lg: "flex-end",
            xs: "space-between",
          },
        }}
      >
        <StatCard
          main={formatNumber(25000)}
          units="aUTD"
          label="Total Balance"
          sx={{
            width: {
              lg: 200,
              xs: "calc(50% - 12px)",
            },
            marginRight: {
              lg: 3,
              xs: 3,
            },
          }}
        />
        <StatCard
          main={formatNumber(20000)}
          units="aUTD"
          label="Total Balance Pledged"
          sx={{
            width: {
              lg: 200,
              xs: "calc(50% - 12px)",
            },
          }}
        />
      </Box>
    </>
  );
};

export default UserOverview;
