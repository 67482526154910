import React from "react";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import StatCard from "./StatCard";
import { DeleteOutlineRounded } from "@mui/icons-material";

const RefereeTable: React.FC = () => {
  const list = [
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      wallet: "0x2353...3d4h",
      referralLink: "https://client.uniteddao.io/invite?code=2548asi1212331",
      linkValidity: 7,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      wallet: "0x2353...3d4h",
      referralLink: "https://client.uniteddao.io/invite?code=2548asi1212331",
      linkValidity: 7,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      wallet: "0x2353...3d4h",
      referralLink: "https://client.uniteddao.io/invite?code=2548asi1212331",
      linkValidity: 7,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      wallet: "0x2353...3d4h",
      referralLink: "https://client.uniteddao.io/invite?code=2548asi1212331",
      linkValidity: 7,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      wallet: "0x2353...3d4h",
      referralLink: "https://client.uniteddao.io/invite?code=2548asi1212331",
      linkValidity: 7,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      wallet: "0x2353...3d4h",
      referralLink: "https://client.uniteddao.io/invite?code=2548asi1212331",
      linkValidity: 7,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      wallet: "0x2353...3d4h",
      referralLink: "https://client.uniteddao.io/invite?code=2548asi1212331",
      linkValidity: 7,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      wallet: "0x2353...3d4h",
      referralLink: "https://client.uniteddao.io/invite?code=2548asi1212331",
      linkValidity: 7,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      wallet: "0x2353...3d4h",
      referralLink: "https://client.uniteddao.io/invite?code=2548asi1212331",
      linkValidity: 7,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      wallet: "0x2353...3d4h",
      referralLink: "https://client.uniteddao.io/invite?code=2548asi1212331",
      linkValidity: 7,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      wallet: "0x2353...3d4h",
      referralLink: "https://client.uniteddao.io/invite?code=2548asi1212331",
      linkValidity: 7,
    },
  ];

  return (
    <Box sx={{ minWidth: 1000, width: "100%" }}>
      <Box sx={{ display: "flex", mb: 3 }}>
        <StatCard
          main={2}
          label="Pending Referees"
          sx={{ width: 220, mr: 3 }}
        />
      </Box>
      <TableContainer
        sx={{
          height: "100%",
          overflow: "scroll",
          maxHeight: { lg: "calc(100% - 105px)", xs: 205 },
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="left">Name of Referee</TableCell>
              <TableCell align="left">Wallet</TableCell>
              <TableCell align="left">Referral Link</TableCell>
              <TableCell align="left">Link validity</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => (
              <TableRow
                key={row.wallet}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {row.date}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {row.referee}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {row.wallet.slice(0, 5)} ... {row.wallet.slice(-4)}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      color: "#000000",
                      borderRadius: 2,
                      px: 5,
                      py: 0,
                      backgroundColor: (theme) => theme.palette.text.primary,
                      ":hover": {
                        backgroundColor: (theme) =>
                          `${theme.palette.text.primary}80`,
                      },
                    }}
                  >
                    Copy Link
                  </Button>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {row.linkValidity} more days
                </TableCell>
                <TableCell padding="checkbox">
                  <IconButton
                    sx={{ color: (theme) => theme.palette.text.primary }}
                  >
                    <DeleteOutlineRounded />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RefereeTable;
