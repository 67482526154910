import React, { useState } from "react";
// import Create from "../components/UI/CreateReferralLink/Create";
import Success from "../components/UI/CreateReferralLink/Success";
import Create from "../components/UI/CreateReferralLink/Create";

const CreateReferralLink: React.FC = () => {
  const [successGenerate, setSuccessGenerate] = useState<boolean>(false);

  return (
    <>
      {!successGenerate && <Create setSuccessGenerate={setSuccessGenerate} />}
      {successGenerate && (
        <Success
          nameOfReferee={"Jeffrey"}
          refereeWalletAddress={"0x23g7d1dd5b6f9c864d9db560d72a247c178ae86b"}
          amountStake={"20000 USDC"}
          refererWalletAddres={"0x5734d1dd5b6f9c864d9db560d72a247c178a3g4h"}
          setSuccessGenerate={setSuccessGenerate}
        />
      )}
    </>
  );
};

export default CreateReferralLink;
