import React, { useEffect, useState } from "react";
import { CloseRounded, LaunchRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import utd from "../../assets/icons/utd.svg";
import usdc from "../../assets/icons/usdc.svg";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getBalances, selectAccountData } from "../../features/AccountSlice";
import {
  JsonRpcProvider,
  StaticJsonRpcProvider,
} from "@ethersproject/providers";
import { UserAccountData } from "../../models/api/UserAccountData";

interface CoinItemProps {
  label: string;
  icon: string;
  coinBalance: string | undefined;
  fiatValue: number;
}

const CoinItem: React.FC<CoinItemProps> = ({
  label,
  icon,
  coinBalance,
  fiatValue,
}) => {
  return (
    <ListItem>
      <ListItemIcon>
        <img src={icon} alt={label} />
      </ListItemIcon>
      <ListItemText
        primary="UTD"
        sx={{ color: (theme) => theme.palette.text.secondary }}
      />
      <ListItemText
        primary={coinBalance}
        secondary={`$${fiatValue.toFixed(2)}`}
        sx={{ textAlign: "right" }}
      />
    </ListItem>
  );
};

interface MyWalletProps {
  user: {
    balance: number;
    address: string;
    disconnect: () => void;
    connected: boolean;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
  };
  myWalletOpen: boolean;
  setMyWalletOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MyWallet: React.FC<MyWalletProps> = ({
  user: { balance, address, disconnect, provider, connected },
  myWalletOpen,
  setMyWalletOpen,
}) => {
  const toggleDrawerClose = () => setMyWalletOpen(false);
  const dispatch = useAppDispatch();
  const userAccountInfo = useAppSelector(selectAccountData);

  const [balances, setBalances] = useState<
    UserAccountData["balances"] | undefined
  >({
    UTD: "1",
    sUTD: "1",
    aUTD: "1",
    USDC: "1",
  });

  useEffect(() => {
    if (connected) {
      dispatch(getBalances({ address: address, provider: provider }));
    }
  }, [address, connected, dispatch, provider]);

  useEffect(() => {
    if (userAccountInfo.status === "IDLE") {
      setBalances(userAccountInfo.value?.balances);
    }
  }, [userAccountInfo.value?.balances, userAccountInfo.status]);

  return (
    <Drawer
      anchor="right"
      open={myWalletOpen}
      onClose={toggleDrawerClose}
      sx={{
        [`& .MuiDrawer-paper`]: {
          width: {
            lg: 400,
            xs: "80%",
          },
          padding: 2,
          boxSizing: "border-box",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Box>
          <Typography variant="h5">My Wallet</Typography>
        </Box>
        <IconButton
          onClick={toggleDrawerClose}
          sx={{ color: (theme) => theme.palette.text.primary }}
        >
          <CloseRounded />
        </IconButton>
      </Box>
      <Box>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          ${balance.toFixed(2)}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {address.slice(0, 5)} ... {address.slice(-5)}
        </Typography>
      </Box>
      <List>
        <CoinItem
          label="UTC"
          icon={utd}
          coinBalance={balances?.UTD}
          fiatValue={0}
        />
        <CoinItem
          label="UTC"
          icon={utd}
          coinBalance={balances?.aUTD}
          fiatValue={0}
        />
        <CoinItem
          label="UTC"
          icon={utd}
          coinBalance={balances?.sUTD}
          fiatValue={0}
        />
        <CoinItem
          label="UTC"
          icon={usdc}
          coinBalance={balances?.USDC}
          fiatValue={0}
        />
      </List>
      <Divider
        sx={{ mt: 1, backgroundColor: (theme) => theme.palette.text.primary }}
      />
      <Button
        sx={{
          mt: 3,
          color: (theme) => theme.palette.text.primary,
          borderColor: (theme) => theme.palette.text.primary,
          textTransform: "none",
          boxShadow: "none",
          ":hover": {
            backgroundColor: (theme) => `${theme.palette.text.primary}60`,
          },
        }}
        variant="outlined"
        endIcon={<LaunchRounded />}
        href="https://app.sushi.com/swap?inputCurrency=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48&outputCurrency=0x0F9a4eA78BA7C8a2b6284C97BA7895c7d2d0726d"
        target="_blank"
        rel="noopener"
      >
        Get on SushiSwap
      </Button>
      <Button
        sx={{
          mt: 3,
          color: (theme) => theme.palette.text.primary,
          borderColor: (theme) => theme.palette.text.primary,
          textTransform: "none",
          boxShadow: "none",
          ":hover": {
            backgroundColor: (theme) => `${theme.palette.text.primary}60`,
          },
        }}
        variant="outlined"
        endIcon={<LaunchRounded />}
        href="https://app.uniswap.org/#/swap?chain=mainnet&inputCurrency=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48&outputCurrency=0x0F9a4eA78BA7C8a2b6284C97BA7895c7d2d0726d"
        target="_blank"
        rel="noopener"
      >
        Get on UniSwap
      </Button>
      <Box
        sx={{
          position: "absolute",
          bottom: "5%",
          display: "flex",
          justifyContent: "center",
          width: (theme) => `calc(100% - ${theme.spacing(2)} * 2)`,
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: (theme) => theme.palette.text.primary,
            color: "#000000",
            textTransform: "none",
            boxShadow: "none",
            width: "80%",
            ":hover": {
              backgroundColor: (theme) => `${theme.palette.text.primary}80`,
            },
          }}
          onClick={disconnect}
        >
          Disconnect
        </Button>
      </Box>
    </Drawer>
  );
};

export default MyWallet;
