import { Box, Button, Card, Typography, useTheme } from "@mui/material";

import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import ReferralSummary from "./Sections/ReferralSummary";

interface SuccessProp {
  nameOfReferee: string;
  refereeWalletAddress: string;
  amountStake: string;
  refererWalletAddres: string;
  setSuccessGenerate: Dispatch<SetStateAction<boolean>>;
}

const Success = (props: SuccessProp) => {
  const {
    nameOfReferee,
    refereeWalletAddress,
    amountStake,
    refererWalletAddres,
    setSuccessGenerate,
  } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: {
            xs: "block",
            lg: "flex",
          },
          height: {
            xs: "calc(100% - 50% - 20px)",
            lg: "calc(100% - 50% - 50px)",
          },
        }}
      >
        <Card
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            borderRadius: "12px",
            p: 3,
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                justifyItems: "center",
                p: "15px 0 15px 0",
                "& .MuiTypography-root": {
                  color: theme.palette.text.primary,
                },
              }}
            >
              <Typography
                variant="subtitle1"
                color="initial"
                sx={{ textAlign: "center" }}
              >
                Referral Link Successfully Created!
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                mb: 2,
                mt: 3,
                p: {
                  xs: "0 0 0 0",
                  lg: "0 150px 0 150px",
                },
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
              }}
            >
              <ReferralSummary
                nameOfReferee={nameOfReferee}
                refereeWalletAddress={refereeWalletAddress}
                amountStake={amountStake}
                refererWalletAddres={refererWalletAddres}
              />
            </Box>
            <Box
              sx={{
                display: {
                  xs: "block",
                  lg: "flex",
                },
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: { xs: "block", lg: "flex" },
                  justifyContent: "flex-end",
                  alignContent: "center",
                  padding: 1,
                  textAlign: {
                    xs: "center",
                  },
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: theme.palette.text.primary,
                    backgroundColor: "Transparent",
                    border: "2px solid " + theme.palette.text.primary,
                    "&:hover": {
                      color: "#FFF",
                      backgroundColor: theme.palette.secondary.main,
                    },
                  }}
                  onClick={() => {
                    setSuccessGenerate(false);
                  }}
                >
                  Create Another
                </Button>
              </Box>
              <Box
                sx={{
                  display: { xs: "block", lg: "flex" },
                  justifyContent: "flex-end",
                  alignContent: "center",
                  padding: 1,
                  textAlign: {
                    xs: "center",
                  },
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    color: "#000",
                    backgroundColor: theme.palette.text.primary,
                    "&:hover": {
                      color: "#FFF",
                      backgroundColor: theme.palette.secondary.main,
                    },
                  }}
                  onClick={() => navigate("/")}
                >
                  Go to Dashboard
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default Success;
