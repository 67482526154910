import { Box, useTheme } from "@mui/material";
import InputDataField from "../../common/InputDataField";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { formatNumber } from "../../../common/helpers/NumberHandler";
import { Dispatch, SetStateAction, useState } from "react";

interface StakeSummaryInfo {
  stakingDate: string;
  stakingBalance: number;
  stakingAmount: number;
  nextRewardYield: string;
}

interface StakeSummaryProps {
  error: boolean;
  data: StakeSummaryInfo;
}

const StakeSummary: React.FC<StakeSummaryProps> = (
  props: StakeSummaryProps
) => {
  const { error, data } = props;
  const theme = useTheme();

  const ErrorIcon = error ? (
    <ErrorRoundedIcon
      sx={{ color: theme.palette.error.main, fontSize: "15px" }}
    />
  ) : undefined;

  return (
    <>
      <Box
        sx={{
          display: {
            xs: "flex",
            lg: "flex",
          },
          p: { lg: "4px 150px 4px 150px" },
          pt: { xs: 1 },
          pb: { xs: 1 },
        }}
      >
        <InputDataField
          fieldName="Start Date of Staking"
          fieldData={data.stakingDate}
          sxFieldName={{
            textAlign: { xs: "left", lg: "left" },
            color: theme.palette.text.secondary,
          }}
          sxFieldData={{
            textAlign: { xs: "right", lg: "right" },
            color: theme.palette.text.secondary,
          }}
        />
      </Box>
      <Box
        sx={{
          display: {
            xs: "flex",
            lg: "flex",
          },
          p: { lg: "4px 150px 4px 150px" },
          pt: { xs: 1 },
          pb: { xs: 1 },
        }}
      >
        <InputDataField
          fieldName="Staked Balance"
          fieldData={formatNumber(data.stakingBalance).toString() + " sUTD"}
          sxFieldName={{
            textAlign: { xs: "left", lg: "left" },
            color: theme.palette.text.secondary,
          }}
          sxFieldData={{
            textAlign: { xs: "right", lg: "right" },
            color: theme.palette.text.secondary,
          }}
        />
      </Box>
      <Box
        sx={{
          display: {
            xs: "flex",
            lg: "flex",
          },
          p: { lg: "4px 150px 4px 150px" },
          pt: { xs: 1 },
          pb: { xs: 1 },
          alignItems: "center",
        }}
      >
        <InputDataField
          fieldName="Amount to Stake"
          fieldData={formatNumber(data.stakingAmount).toString() + " USDC"}
          sxFieldName={{
            textAlign: { xs: "left", lg: "left" },
            color: theme.palette.text.secondary,
          }}
          sxFieldData={{
            textAlign: { xs: "right", lg: "right" },
            color: () => {
              if (error) {
                return theme.palette.error.main;
              }
              return theme.palette.text.secondary;
            },
          }}
          icon={ErrorIcon}
        />
      </Box>
      <Box
        sx={{
          display: {
            xs: "flex",
            lg: "flex",
          },
          p: { lg: "4px 150px 4px 150px" },
          pt: { xs: 1 },
          pb: { xs: 1 },
        }}
      >
        <InputDataField
          fieldName="Next Reward Yield"
          fieldData={data.nextRewardYield}
          sxFieldName={{
            textAlign: { xs: "left", lg: "left" },
            color: theme.palette.text.secondary,
          }}
          sxFieldData={{
            textAlign: { xs: "right", lg: "right" },
            color: theme.palette.text.secondary,
          }}
        />
      </Box>
    </>
  );
};

export default StakeSummary;
