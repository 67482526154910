interface ContractAddressConfig {
    usdcAddress: string;
    authorityAddress: string;
    utdAddress: string;
    treasuryAddress: string;
    sutdAddress: string;
    autdAddress: string;
    stakingAddress: string;
    distrbutorAddress: string;
    bondDepositoryAddress: string;
    utdUsdcTokenPairAddress: string;
  }

export const addresses: ContractAddressConfig = {
    usdcAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    authorityAddress: '0x9Ed2e7451740Eb1aC02775aEd90A485280C53F6C',
    utdAddress: '0x0F9a4eA78BA7C8a2b6284C97BA7895c7d2d0726d',
    treasuryAddress: '0x67d08F98aa796c0513df29bD9ada9Dd567a4cabC',
    sutdAddress: '0x1a871589aB3a82dE9897a180a37F792968597839',
    autdAddress: '0xD58e1EAa15410275700E08754088490c4132Cc94',
    stakingAddress: '0x00424be2d428202DaeA84754aDFAF7eE3Bf880f3',
    distrbutorAddress: '0x9b5BF4B693469901c5c664Bd03D650DE822CF990',
    bondDepositoryAddress: '0x74Fa8387589223F2417EC4890d059a9aEAf9bCD1',
    utdUsdcTokenPairAddress: '',
  };
  