import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { RootModule } from "../../models";
import {
  Box,
  Button,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useTheme,
} from "@mui/material";
import { Route, Routes } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo/logo.png";
import { useWeb3Context } from "../../common/hooks/useWeb3Context";
import { AccountBalanceWalletOutlined, MenuRounded } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import DisconnectWallet from "../../assets/icons/disconnect-wallet.svg";
import Discord from "../../assets/icons/discord.svg";
import Medium from "../../assets/icons/medium.svg";
import Telegram from "@mui/icons-material/Telegram";
import Youtube from "../../assets/icons/youtube.svg";
import Twitter from "../../assets/icons/twitter.svg";
import MyWallet from "../UI/MyWallet";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(1),
  textDecoration: "none",
}));

interface Props {
  rootModule: RootModule[];
}

const PageLayout: React.FC<Props> = ({ rootModule }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { address, connect, connected, disconnect, provider } =
    useWeb3Context();
  const [role, setRole] = useState("Client");

  const [myWalletOpen, setMyWalletOpen] = useState<boolean>(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState<boolean>(false);
  const lg = useMediaQuery("(min-width:1280px)");

  return (
    <Box>
      <CssBaseline />
      <Drawer
        open={!lg && mobileDrawerOpen}
        onClose={() => setMobileDrawerOpen(false)}
        variant={lg ? "permanent" : "temporary"}
        sx={{
          width: 260,
          [`& .MuiDrawer-paper`]: { width: 260, boxSizing: "border-box" },
        }}
      >
        <Toolbar />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <img src={logo} width={160} height={160} alt="United DAO" />
        </Box>
        <Box sx={{ overflow: "auto" }}>
          <List>
            {rootModule
              .filter(({ roleRequired }) => roleRequired === role)
              .filter(({ showInMenu }) => showInMenu === true)
              .map(({ label, path, icon }) => (
                <StyledLink to={path} key={label}>
                  <ListItem button onClick={() => setMobileDrawerOpen(false)}>
                    <ListItemIcon
                      sx={{
                        color: (theme) => theme.palette.text.primary,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={label} />
                  </ListItem>
                </StyledLink>
              ))}
          </List>
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            height: 75,
            backgroundColor: theme.palette.text.primary,
            zIndex: 1000,
            width: 260,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              height: 75,
            }}
          >
            <IconButton
              onClick={() =>
                window.open("https://discord.gg/uniteddao", "_blank")
              }
            >
              <img src={Discord} alt="Discord" />
            </IconButton>
            <IconButton
              onClick={() =>
                window.open("https://twitter.com/UnitedDaoUTD", "_blank")
              }
            >
              <img src={Twitter} alt="Twitter" />
            </IconButton>
            <IconButton
              onClick={() =>
                window.open("https://medium.com/uniteddao", "_blank")
              }
            >
              <img src={Medium} alt="Facebook" />
            </IconButton>
            <IconButton
              onClick={() =>
                window.open(
                  "https://www.youtube.com/c/UnitedDAOofficial",
                  "_blank"
                )
              }
            >
              <img src={Youtube} alt="Youtube" />
            </IconButton>
            <IconButton
              onClick={() => window.open("https://t.me/officialUTD", "_blank")}
            >
              <Telegram sx={{ color: theme.palette.background.paper }} />
            </IconButton>
          </Box>
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          ml: {
            lg: "260px",
            xs: 0,
          },
          flexGrow: 1,
          p: 3,
          height: "100vh",
          minHeight: 1200,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: {
              lg: "flex-end",
              xs: "space-between",
            },
            height: 60,
            paddingBottom: 3,
          }}
        >
          <IconButton
            sx={{
              display: { lg: "none", xs: "block" },
              color: (theme) => theme.palette.text.primary,
            }}
            onClick={() => setMobileDrawerOpen((prev) => !prev)}
          >
            <MenuRounded />
          </IconButton>
          {connected && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  textTransform: "none",
                  boxShadow: "none",
                  marginRight: (theme) => theme.spacing(1),
                }}
                onClick={connected ? () => setMyWalletOpen(true) : connect}
              >
                {connected
                  ? `My Wallet - 
                  ${address.slice(0, 5)} ... ${address.slice(-5)}`
                  : "Connect Wallet"}
              </Button>
              {lg ? (
                <Button
                  variant="contained"
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                    textTransform: "none",
                    boxShadow: "none",
                  }}
                  onClick={() => {
                    disconnect();
                    navigate("/");
                  }}
                  startIcon={<AccountBalanceWalletOutlined />}
                >
                  Disconnect
                </Button>
              ) : (
                <IconButton
                  onClick={() => {
                    disconnect();
                    navigate("/");
                  }}
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    borderRadius: 2,
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  <img src={DisconnectWallet} alt="Disconnect Wallet" />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
        {connected && role === "Ally" ? (
          <Routes>
            {rootModule
              .filter(({ roleRequired }) => roleRequired === "Ally")
              .map((module: RootModule) => {
                return (
                  <Route
                    key={`${module.path}`}
                    path={`${module.path}`}
                    element={module.page}
                  />
                );
              })}
          </Routes>
        ) : (
          <Routes>
            {rootModule
              .filter(({ roleRequired }) => roleRequired === "Client")
              .map((module: RootModule) => {
                return (
                  <Route
                    key={`${module.path}`}
                    path={`${module.path}`}
                    element={module.page}
                  />
                );
              })}
          </Routes>
        )}
        <MyWallet
          user={{
            balance: 0,
            address: address,
            disconnect: disconnect,
            provider: provider,
            connected: connected,
          }}
          myWalletOpen={myWalletOpen}
          setMyWalletOpen={setMyWalletOpen}
        />
      </Box>
    </Box>
  );
};

export default PageLayout;
