import React from "react";
import { BrowserRouter } from "react-router-dom";
import { LinkRounded, PieChartOutlineRounded } from "@mui/icons-material";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import PageLayout from "../components/templates/PageLayout";
import Dashboard from "./Dashboard";
import { RootModule } from "../models";
import CreateReferralLink from "./CreateReferralLink";
import Summary from "./Summary";
import LandingPage from "./LandingPage";
import StakingDashboard from "./StakingDashboard";

const Main: React.FC = () => {
  const rootModule: RootModule[] = [
    {
      label: "Dashboard",
      path: "/",
      icon: <PieChartOutlineRounded />,
      page: <Dashboard />,
      roleRequired: "Ally",
      showInMenu: true,
    },
    {
      label: "Create Referral Link",
      path: "/referral",
      icon: <LinkRounded />,
      page: <CreateReferralLink />,
      roleRequired: "Ally",
      showInMenu: true,
    },
    {
      label: "Client Dashboard",
      path: "/",
      icon: <PieChartOutlineRounded />,
      page: <LandingPage />,
      roleRequired: "Client",
      showInMenu: true,
    },
    {
      label: "Bond & Stake Summary",
      path: "/summary",
      icon: <AccountBalanceRoundedIcon />,
      page: <Summary />,
      roleRequired: "Client",
      showInMenu: false,
    },
    {
      label: "Single Stake",
      path: "/staking-dashboard",
      icon: <AccountBalanceRoundedIcon />,
      page: <StakingDashboard />,
      roleRequired: "Client",
      showInMenu: false,
    },
  ];

  return (
    <BrowserRouter>
      <PageLayout rootModule={rootModule} />
    </BrowserRouter>
  );
};

export default Main;
