import { Box, Card, Divider, Typography, useTheme } from "@mui/material";

import { Dispatch, SetStateAction } from "react";
import GenerateLinkForm from "./Sections/GenerateLinkForm";
import StakingOverview from "./Sections/StakingOverview";

interface createProps {
  setSuccessGenerate: Dispatch<SetStateAction<boolean>>;
}

const Create: React.FC<createProps> = (props: createProps) => {
  const theme = useTheme();
  let nextRebaseTime: string = "5hrs, 13 mins to next release";

  return (
    <>
      <Box
        sx={{
          display: {
            xs: "block",
            lg: "flex",
          },
          height: {
            xs: "calc(100% - 20px)",
            lg: "calc(100% - 50px)",
          },
        }}
      >
        <Card
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            borderRadius: "12px",
            p: 3,
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                justifyItems: "center",
                p: "15px 0 15px 0",
                "& .MuiTypography-root": {
                  color: theme.palette.text.primary,
                },
              }}
            >
              <Typography variant="subtitle1" color="initial">
                Create Referral Link
              </Typography>
            </Box>

            <Divider
              light
              sx={{
                backgroundColor: theme.palette.text.primary,
              }}
            />
            <Box
              sx={{
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
              }}
            >
              <Typography
                variant="subtitle1"
                color="initial"
                sx={{
                  color: theme.palette.text.primary,
                  paddingTop: "12px",
                }}
              >
                Single Staking
              </Typography>
              <Typography
                variant="caption"
                color="initial"
                sx={{
                  color: theme.palette.text.primary,
                  position: "relative",
                  bottom: 13,
                  fontSize: 10,
                  fontFamily: "'Roboto'",
                }}
              >
                {nextRebaseTime}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                mb: 2,
                p: {
                  xs: "0 0 0 0",
                  lg: "0 150px 0 150px",
                },
              }}
            >
              <Box>
                <StakingOverview />
                <GenerateLinkForm
                  setSuccessGenerate={props.setSuccessGenerate}
                />
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default Create;
