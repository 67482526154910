import { Box, Typography, useTheme } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const StakingOverview = () => {
  const theme = useTheme();
  let apyPercentage: string = "3096.9%";
  let totalValueDeposited: string = "$811,041,431";
  let currentIndex: string = "68.2 UTD";

  return (
    <Box
      sx={{
        display: { lg: "flex", xs: "block" },
        justifyContent: "space-evenly",
        mb: 3,
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="subtitle1"
          color="initial"
          sx={{
            color: theme.palette.text.primary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          APY{" "}
          <ErrorOutlineIcon
            sx={{
              p: "2px",
              m: "2px",
            }}
          />
        </Typography>
        <Typography
          variant="subtitle1"
          color="initial"
          sx={{ color: theme.palette.text.secondary }}
        >
          {apyPercentage}
        </Typography>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="subtitle1"
          color="initial"
          sx={{
            color: theme.palette.text.primary,
          }}
        >
          Total Value Deposited
        </Typography>
        <Typography
          variant="subtitle1"
          color="initial"
          sx={{ color: theme.palette.text.secondary }}
        >
          {totalValueDeposited}
        </Typography>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="subtitle1"
          color="initial"
          sx={{
            color: theme.palette.text.primary,
          }}
        >
          Current Index
        </Typography>
        <Typography
          variant="subtitle1"
          color="initial"
          sx={{ color: theme.palette.text.secondary }}
        >
          {currentIndex}
        </Typography>
      </Box>
    </Box>
  );
};

export default StakingOverview;
