import { Box, Typography, TextField, Button, useTheme } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
interface createProps {
  setSuccessGenerate: Dispatch<SetStateAction<boolean>>;
}

const GenerateLinkForm: React.FC<createProps> = (props: createProps) => {
  const theme = useTheme();
  let refereeAddress: string = "0x5734d1dd5b6f9c864d9db560d72a247c178a3g4h";

  return (
    <>
      <Box
        sx={{
          m: "15px 0 15px 0",
          display: {
            xs: "block",
            lg: "flex",
          },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              lg: "30%",
            },
            pr: {
              xs: 0,
              lg: "10px",
            },
          }}
        >
          <Typography
            variant="subtitle1"
            color="initial"
            sx={{ color: theme.palette.text.primary }}
          >
            Name of Referee
          </Typography>
        </Box>
        <Box
          sx={{
            width: {
              xs: "100%",
              lg: "100%",
            },
          }}
        >
          <TextField
            fullWidth
            label="Name"
            id="fullWidth"
            sx={{
              background: "#374575",
              borderRadius: "8px",
              "& .MuiInputLabel-root": {
                color: theme.palette.text.secondary,
              },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          m: "15px 0 15px 0",
          display: {
            xs: "block",
            lg: "flex",
          },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              lg: "30%",
            },
            pr: {
              xs: 0,
              lg: "10px",
            },
          }}
        >
          <Typography
            variant="subtitle1"
            color="initial"
            sx={{ color: theme.palette.text.primary }}
          >
            Referee’s Wallet Address
          </Typography>
        </Box>
        <Box
          sx={{
            width: {
              xs: "100%",
              lg: "100%",
            },
          }}
        >
          <TextField
            fullWidth
            label="Wallet Address"
            id="fullWidth"
            sx={{
              background: "#374575",
              borderRadius: "8px",
              "& .MuiInputLabel-root": {
                color: theme.palette.text.secondary,
              },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          m: "15px 0 15px 0",
          display: {
            xs: "block",
            lg: "flex",
          },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              lg: "30%",
            },
            pr: {
              xs: 0,
              lg: "10px",
            },
          }}
        >
          <Typography
            variant="subtitle1"
            color="initial"
            sx={{ color: theme.palette.text.primary }}
          >
            Amount to Stake
          </Typography>
        </Box>
        <Box
          sx={{
            display: {
              xs: "flex",
              lg: "flex",
            },
            width: {
              xs: "100%",
              lg: "100%",
            },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: {
                xs: "85%",
                lg: "100%",
              },
            }}
          >
            <TextField
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              fullWidth
              label="Amount"
              id="fullWidth"
              InputLabelProps={{
                color: "secondary",
              }}
              sx={{
                background: "#374575",
                borderRadius: "8px",
                "& .MuiInputLabel-root": {
                  color: theme.palette.text.secondary,
                },
              }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "15%", lg: "20%" },
            }}
          >
            <Typography
              variant="subtitle1"
              color="initial"
              sx={{
                color: theme.palette.text.primary,
                textAlign: "center",
              }}
            >
              USDC
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          m: "15px 0 15px 0",
          display: {
            xs: "block",
            lg: "flex",
          },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              lg: "30%",
            },
          }}
        >
          <Typography
            variant="subtitle1"
            color="initial"
            sx={{ color: theme.palette.text.primary }}
          >
            Referee’s Wallet Address
          </Typography>
        </Box>
        <Box
          sx={{
            width: {
              xs: "100%",
              lg: "100%",
            },
          }}
        >
          <Typography
            variant="subtitle1"
            color="initial"
            sx={{ color: theme.palette.text.primary }}
          >
            {refereeAddress}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: {
            xs: "100%",
            lg: "100%",
          },
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            props.setSuccessGenerate(true);
          }}
          sx={{
            color: "#000",
            backgroundColor: theme.palette.text.primary,
            "&:hover": {
              color: "#FFF",
              backgroundColor: theme.palette.secondary.main,
            },
          }}
        >
          Generate Referral Link
        </Button>
      </Box>
    </>
  );
};

export default GenerateLinkForm;
