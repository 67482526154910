import { Box, Typography, useTheme } from "@mui/material";

interface ValueBoxProps {
  title: string;
  icon?: React.ReactNode;
  value: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

const ValueBox: React.FC<ValueBoxProps> = (props: ValueBoxProps) => {
  const { title, icon, value, prefix, suffix } = props;
  const theme = useTheme();
  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="subtitle1"
          color="initial"
          sx={{
            color: theme.palette.text.primary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {title}
          {icon ? icon : null}
        </Typography>
        <Typography
          variant="subtitle1"
          color="initial"
          sx={{ color: theme.palette.text.secondary }}
        >
          {prefix ? prefix : null}
          {value}
          {suffix ? suffix : null}
        </Typography>
      </Box>
    </>
  );
};

export default ValueBox;
