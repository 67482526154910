import {
  Box,
  Button,
  Card,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import ValueBox from "./ValueBox";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import StakeSummary from "./StakeSummary";
import InputDataField from "../../common/InputDataField";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectAccountData } from "../../../features/AccountSlice";
import { formatNumber } from "../../../common/helpers/NumberHandler";

interface StakeSummaryInfo {
  stakingDate: string;
  stakingBalance: number;
  stakingAmount: number;
  nextRewardYield: string;
}

const cardStyles = {
  justifyContent: "space-between",
  borderRadius: 2,
  padding: 3,
};

const BondStake = () => {
  const theme = useTheme();
  const userAccountInfo = useAppSelector(selectAccountData);

  const initialData: StakeSummaryInfo = {
    stakingDate: "20-02-2022",
    stakingBalance: 10000,
    stakingAmount: 20000,
    nextRewardYield: "0.2390% = 0.00 sUTD",
  };

  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<StakeSummaryInfo>(initialData);
  const [approve, setApprove] = useState<boolean>(false);
  const [confirmStake, setConfirmStake] = useState<boolean>(false);
  let nextRebaseTime: string = "5hrs, 13 mins to next release";

  const notEnoughUSDC = error ? (
    <Typography
      variant="subtitle2"
      color="initial"
      sx={{ color: theme.palette.error.main }}
    >
      You have insufficient USDC in your wallet and cannot approve this
      transaction. Purchase USDC and refresh this screen to see your updated
      wallet.
    </Typography>
  ) : (
    <Typography
      variant="subtitle2"
      color="initial"
      sx={{ color: theme.palette.text.primary }}
    >
      First time staking UTD? Approve UnitedDao to make transactions.
    </Typography>
  );

  const buttonGroup = error ? (
    <>
      <Box
        sx={{
          display: { xs: "block", lg: "flex" },
          justifyContent: "flex-end",
          alignContent: "center",
          padding: 1,
          textAlign: {
            xs: "center",
          },
        }}
      >
        <Button
          variant="outlined"
          sx={{
            color: theme.palette.text.primary,
            backgroundColor: "Transparent",
            border: "2px solid " + theme.palette.text.primary,
            "&:hover": {
              color: "#FFF",
              backgroundColor: theme.palette.secondary.main,
            },
          }}
          onClick={() => {
            return false;
          }}
        >
          Refresh Wallet
        </Button>
      </Box>

      <Box
        sx={{
          display: { xs: "block", lg: "flex" },
          justifyContent: "flex-end",
          alignContent: "center",
          padding: 1,
          textAlign: {
            xs: "center",
          },
        }}
      >
        <Button
          variant="contained"
          sx={{
            color: "#000",
            backgroundColor: theme.palette.text.primary,
            "&:hover": {
              color: "#FFF",
              backgroundColor: theme.palette.secondary.main,
            },
          }}
          onClick={() =>
            window.open(
              "https://app.uniswap.org/#/swap?chain=mainnet&inputCurrency=ETH&outputCurrency=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
            )
          }
        >
          Purchase USDC
        </Button>
      </Box>
    </>
  ) : (
    <>
      <Box
        sx={{
          display: { xs: "block", lg: "flex" },
          justifyContent: "flex-end",
          alignContent: "center",
          padding: 1,
          textAlign: {
            xs: "center",
          },
        }}
      >
        <Button
          variant="contained"
          sx={{
            color: "#000",
            backgroundColor: theme.palette.text.primary,
            "&:hover": {
              color: "#FFF",
              backgroundColor: theme.palette.secondary.main,
            },
          }}
          onClick={() => {
            return false;
          }}
        >
          Approve
        </Button>
      </Box>
    </>
  );

  const buttonStake = (
    <>
      <Box
        sx={{
          display: { xs: "block", lg: "flex" },
          justifyContent: "flex-end",
          alignContent: "center",
          padding: 1,
          textAlign: {
            xs: "center",
          },
        }}
      >
        <Button
          variant="contained"
          sx={{
            color: "#000",
            backgroundColor: theme.palette.text.primary,
            pl: {
              xs: 5,
            },
            pr: {
              xs: 5,
            },
            "& .Mui-disabled": {
              color: theme.palette.text.secondary,
              WebkitTextFillColor: theme.palette.text.primary + "!important",
            },
            "&:hover": {
              color: "#FFF",
              backgroundColor: theme.palette.secondary.main,
            },
          }}
          onClick={() => {
            return false;
          }}
          disabled={!confirmStake ? true : false}
        >
          Stake
        </Button>
      </Box>
    </>
  );

  useEffect(() => {
    if (userAccountInfo.status === "IDLE") {
      let balances = userAccountInfo.value?.balances;
      if (data?.stakingAmount > Number(balances?.USDC)) {
        setError(true);
      }
      console.log(userAccountInfo.value?.balances);
    }
  }, [
    userAccountInfo.value?.balances,
    userAccountInfo.status,
    data?.stakingAmount,
  ]);

  return (
    <>
      <Box sx={{ maxHeight: "calc(100% - 60px)", display: "flex" }}>
        <Card
          sx={{
            ...cardStyles,
            width: "100%",
            height: { lg: "calc(50% - 20px)", xs: "fit-content" },
            marginBottom: 3,
          }}
        >
          <Box
            sx={{
              display: { xs: "block", md: "flex", lg: "flex" },
              justifyContent: "center",
              textAlign: {
                xs: "center",
              },
            }}
          >
            <Typography
              variant="subtitle1"
              color="initial"
              sx={{ color: theme.palette.text.primary }}
            >
              {"Bond & Stake Summary"}
            </Typography>
          </Box>
          <Box>
            <Divider
              light
              sx={{
                backgroundColor: theme.palette.text.primary,
              }}
            />
          </Box>
          <Box
            sx={{
              textAlign: {
                xs: "center",
                lg: "left",
              },
            }}
          >
            <Typography
              variant="subtitle1"
              color="initial"
              sx={{
                color: theme.palette.text.primary,
                paddingTop: "12px",
              }}
            >
              Single Staking
            </Typography>
            <Typography
              variant="caption"
              color="initial"
              sx={{
                color: theme.palette.text.primary,
                position: "relative",
                bottom: 13,
                fontSize: 10,
                fontFamily: "'Roboto'",
              }}
            >
              {nextRebaseTime}
            </Typography>
          </Box>
          <Box
            sx={{
              display: { lg: "flex", xs: "block" },
              justifyContent: "space-evenly",
              mb: 3,
            }}
          >
            <ValueBox
              title={"APY"}
              icon={
                <ErrorOutlineIcon
                  sx={{
                    p: "2px",
                    m: "2px",
                  }}
                />
              }
              value={formatNumber(3096.9)}
              suffix={<Box component="span">%</Box>}
            />
            <ValueBox
              title={"Total Value Deposited"}
              value={formatNumber(811041431)}
              prefix={<Box component="span">$</Box>}
            />
            <ValueBox
              title={"Current Index"}
              value={formatNumber(68.2)}
              suffix={<Box component="span"> UTD</Box>}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              mb: 2,
              mt: 3,
              p: {
                xs: 0,
                lg: "0 150px 0 150px",
              },
              display: {
                xs: "block",
                lg: "flex",
              },
              flexDirection: {
                lg: "column",
              },
              justifyContent: "center",
            }}
          >
            <StakeSummary error={error} data={data} />
          </Box>
          <Box
            sx={{
              width: "100%",
              mb: 2,
              mt: 3,
              p: {
                xs: "0 0 0 0",
                lg: "0 150px 0 150px",
              },
              display: {
                xs: "block",
                lg: "flex",
              },
              flexDirection: {
                lg: "column",
              },
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: {
                  xs: "flex",
                  lg: "flex",
                },
                p: { lg: "4px 150px 4px 150px" },
                pt: { xs: 1 },
                pb: { xs: 1 },
              }}
            >
              <InputDataField
                fieldName="Referrer’s Wallet Address"
                fieldData="0x37d7d1dd5b6f9c864d9db560d72a247c178ae86b"
                sxFieldName={{
                  textAlign: { xs: "left", lg: "left" },
                  color: theme.palette.text.primary,
                }}
                sxFieldData={{
                  color: theme.palette.text.primary,
                  textAlign: { xs: "right", lg: "right" },
                  wordWrap: "break-word",
                }}
              />
            </Box>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  lg: "flex",
                },
                p: { lg: "4px 150px 4px 150px" },
                pt: { xs: 1 },
                pb: { xs: 1 },
              }}
            >
              <InputDataField
                fieldName="Link Validity"
                fieldData="7 more days"
                sxFieldName={{
                  textAlign: { xs: "left", lg: "left" },
                  color: theme.palette.text.primary,
                }}
                sxFieldData={{
                  color: theme.palette.text.primary,
                  textAlign: { xs: "right", lg: "right" },
                }}
              />
            </Box>
            <Box
              sx={{
                display: {
                  xs: "block",
                  lg: "flex",
                },
                justifyContent: "center",
              }}
            >
              {!approve && buttonGroup}
              {approve && !error && buttonStake}
            </Box>
            <Box
              sx={{
                textAlign: "center",
                p: 2,
              }}
            >
              {!approve && buttonGroup && notEnoughUSDC}
              {approve && !error && null}
            </Box>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default BondStake;
