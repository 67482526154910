import { Box, Typography, SxProps, Theme } from "@mui/material";

interface InputDataFieldProps {
  fieldName: string;
  fieldData: string;
  sxBox?: SxProps<Theme>;
  sxFieldName?: SxProps<Theme>;
  sxFieldData?: SxProps<Theme>;
  icon?: React.ReactNode;
}

const InputDataField: React.FC<InputDataFieldProps> = (
  props: InputDataFieldProps
) => {
  const { fieldName, fieldData, sxBox, sxFieldName, sxFieldData, icon } = props;
  return (
    <>
      <Box
        sx={{
          ...sxBox,
          width: {
            xs: "100%",
            lg: " 50%",
          },
        }}
      >
        <Typography
          variant="subtitle2"
          color="initial"
          sx={{ ...sxFieldName, maxWidth: { lg: "unset", xs: "100%" } }}
        >
          {fieldName}
        </Typography>
      </Box>
      <Box
        sx={{
          ...sxBox,
          width: {
            xs: "100%",
            lg: " 50%",
          },
        }}
      >
        <Typography
          variant="subtitle2"
          color="initial"
          sx={{
            ...sxFieldData,
            maxWidth: { lg: "unset", xs: "100%" },
            wordBreak: "break-all",
          }}
        >
          {icon} {fieldData}
        </Typography>
      </Box>
    </>
  );
};

export default InputDataField;
