import React from "react";
import { Box, Card } from "@mui/material";
import UserOverview from "../components/UI/Dashboard/UserOverview";
import CommissionTable from "../components/UI/Dashboard/CommissionTable";
import RefereeTable from "../components/UI/Dashboard/RefereeTable";

const cardStyles = {
  display: {
    lg: "flex",
    xs: "block",
  },
  justifyContent: "space-between",
  borderRadius: 2,
  padding: 3,
};

const Dashboard: React.FC = () => {
  return (
    <Box
      sx={{
        height: "calc(100% - 60px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Card
        sx={{
          ...cardStyles,
          height: { lg: 140, xs: "fit-content" },
          marginBottom: 3,
        }}
      >
        <UserOverview />
      </Card>

      <Card
        sx={{
          ...cardStyles,
          height: "45%",
          overflowX: "scroll",
          marginBottom: 3,
        }}
      >
        <CommissionTable />
      </Card>

      <Card
        sx={{
          ...cardStyles,
          height: {
            lg: "calc(100% - 45% - 140px)",
            xs: "calc(100% - 45% - 270px)",
          },
          overflowX: "scroll",
        }}
      >
        <RefereeTable />
      </Card>
    </Box>
  );
};

export default Dashboard;
