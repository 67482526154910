import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import StatCard from "./StatCard";
import { formatNumber } from "../../../common/helpers/NumberHandler";
// import { useInfiniteScrollingObserver } from "../../../app/hooks";

const CommissionTable: React.FC = () => {
  // const list = useAppSelector(selectCoinList);
  const list = [
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      rank: "Associate",
      wallet: "0x2353...3d4h",
      stakedAmount: 10,
      status: "Ongoing",
      commission: 10.01,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      rank: "Associate",
      wallet: "0x2353...3d4h",
      stakedAmount: 10,
      status: "Unstaked",
      commission: 10.01,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      rank: "Associate",
      wallet: "0x2353...3d4h",
      stakedAmount: 10,
      status: "Ongoing",
      commission: 10.01,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      rank: "Associate",
      wallet: "0x2353...3d4h",
      stakedAmount: 10,
      status: "Ongoing",
      commission: 10.01,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      rank: "Associate",
      wallet: "0x2353...3d4h",
      stakedAmount: 10,
      status: "Ongoing",
      commission: 10.01,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      rank: "Associate",
      wallet: "0x2353...3d4h",
      stakedAmount: 10,
      status: "Ongoing",
      commission: 10.01,
    },

    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      rank: "Associate",
      wallet: "0x2353...3d4h",
      stakedAmount: 10,
      status: "Ongoing",
      commission: 10.01,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      rank: "Associate",
      wallet: "0x2353...3d4h",
      stakedAmount: 10,
      status: "Ongoing",
      commission: 10.01,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      rank: "Associate",
      wallet: "0x2353...3d4h",
      stakedAmount: 10,
      status: "Ongoing",
      commission: 10.01,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      rank: "Associate",
      wallet: "0x2353...3d4h",
      stakedAmount: 10,
      status: "Ongoing",
      commission: 10.01,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      rank: "Associate",
      wallet: "0x2353...3d4h",
      stakedAmount: 10,
      status: "Ongoing",
      commission: 10.01,
    },
    {
      date: "25-02-2022",
      referee: "Hubert Blaine Wolfeschec",
      rank: "Associate",
      wallet: "0x2353...3d4h",
      stakedAmount: 10,
      status: "Ongoing",
      commission: 10.01,
    },
  ];

  // const [lastRef] = useInfiniteScrollingObserver(
  //   coinList.status,
  //   setCoinQueryParams({
  //     ...coinList.coinQueryParams,
  //     page: coinList.coinQueryParams.page + 1,
  //   }),
  //   fetchCoinList({
  //     coinQueryParams: {
  //       ...coinList.coinQueryParams,
  //       page: coinList.coinQueryParams.page + 1,
  //     },
  //     append: true,
  //   }),
  //   coinList.hasMore,
  //   0.1
  // );

  return (
    <Box sx={{ minWidth: 1000, width: "100%" }}>
      <Box sx={{ display: "flex", mb: 3 }}>
        <StatCard
          main={3}
          units="/5"
          label="Active Referees"
          sx={{ width: 220, mr: 3 }}
        />
        <StatCard
          main={formatNumber(500)}
          units="sUTD"
          label="Commission Earned"
          sx={{ width: 220, mr: 3 }}
        />
        <StatCard
          main={formatNumber(200)}
          units="sUTD"
          label="This Month's Commission"
          sx={{ width: 220 }}
        />
      </Box>
      <TableContainer
        sx={{
          height: "100%",
          overflow: "scroll",
          maxHeight: { lg: "calc(100% - 105px)", xs: 370 },
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="left">Name of Referee</TableCell>
              <TableCell align="left">Rank</TableCell>
              <TableCell align="left">Wallet Address</TableCell>
              <TableCell align="left">Staked Amount</TableCell>
              <TableCell align="left">Stake Status</TableCell>
              <TableCell align="left">Commission</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => (
              <TableRow
                key={row.wallet}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {row.date}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {row.referee}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {row.rank}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {row.wallet.slice(0, 5)} ... {row.wallet.slice(-4)}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {row.stakedAmount} sUTD
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        borderRadius: "50%",
                        height: 8,
                        width: 8,
                        mr: 1,
                        backgroundColor: (theme) =>
                          row.status === "Ongoing"
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                      }}
                    />
                    {row.status}
                  </Box>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {row.commission} sUTD
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CommissionTable;
