import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { UserAccountInfo } from "../models";
import { GenericState } from "../models/common/GenericState";
import USDCContractABI from "../contracts/abi/USDC.json";
import UTDContractABI from "../contracts/abi/UTD.json";
import sUTDContractABI from "../contracts/abi/sUTD.json";
import aUTDCContractABI from "../contracts/abi/aUTD.json";
import {addresses} from "../contracts/constants";
import { RootState } from "../app/store";


const initialState: GenericState<UserAccountInfo | null> = {
    value: null,
    status: 'IDLE'
};

const CONTRACT = {
    UTD: {
        address: addresses.utdAddress,
        abi: UTDContractABI
    },
    USDC: {
        address: addresses.usdcAddress,
        abi: USDCContractABI
    },
    sUTD: {
        address: addresses.sutdAddress,
        abi: sUTDContractABI
    },
    aUTD: {
        address: addresses.autdAddress,
        abi: aUTDCContractABI
    },
}

export enum NetworkID {
    Mainnet = 1,
}

export interface IBaseAsyncThunk {
    // readonly networkID: NetworkID;
    readonly provider: StaticJsonRpcProvider | JsonRpcProvider;
}
export interface IBaseAddressAsyncThunk extends IBaseAsyncThunk {
  readonly address: string;
}

export const getBalances = createAsyncThunk(
    "account/getBalances",
    async ({ address, provider }: IBaseAddressAsyncThunk) => {
      const utdContract = new ethers.Contract(CONTRACT.UTD.address, CONTRACT.UTD.abi, provider);
      const utdBalance = await utdContract.balanceOf(address);
      const sUtdContract = new ethers.Contract(CONTRACT.sUTD.address, CONTRACT.sUTD.abi, provider);
      const sUtdBalance = await sUtdContract.balanceOf(address);
      const aUtdContract = new ethers.Contract(CONTRACT.aUTD.address, CONTRACT.aUTD.abi, provider);
      const aUtdBalance = await aUtdContract.balanceOf(address);
      const usdcContract = new ethers.Contract(CONTRACT.USDC.address, CONTRACT.USDC.abi,provider);
      const usdcBalance = await usdcContract.balanceOf(address);

      const ethBalance = await provider.getBalance("ethers.eth");

      console.log(ethers.utils.formatEther(ethBalance))
      return {
        balances: {
            UTD: ethers.utils.formatUnits(utdBalance, "gwei"),
            sUTD: ethers.utils.formatUnits(sUtdBalance, "gwei"),
            aUTD: ethers.utils.formatEther(aUtdBalance),
            USDC: ethers.utils.formatUnits(usdcBalance, "gwei"),
        },
      };
    },
  );

  export const selectAccountData: (state: RootState) => GenericState<UserAccountInfo | null>
  = (state: RootState) => state.account;

const accountDataSlice: Slice<GenericState<UserAccountInfo | null>, {}, 'account'> = createSlice({
    name: 'account',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(getBalances.pending, (state) => {
          state.status = 'LOADING';
        })
        .addCase(getBalances.fulfilled, (state, action) => {
          state.status = 'IDLE';
          state.value = action.payload;
        })
        .addCase(getBalances.rejected, (state, action) => {
          state.status = 'FAILED';
          state.error = action.error.message;
        })
    }
})

export default accountDataSlice.reducer;
