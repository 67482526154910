import React from "react";
import { responsiveFontSizes } from "@mui/material";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import Main from "./pages/Main";
import { Web3ContextProvider } from "./common/hooks/useWeb3Context";
import { Web3ChainProvider } from "./common/hooks/useWeb3Chain";

const App: React.FC = () => {
  const theme: Theme = createTheme({
    palette: {
      primary: {
        main: "#16254E",
      },
      secondary: {
        main: "#223057",
      },
      background: {
        default: "#375D8C",
        paper: "#16254E",
      },
      text: {
        primary: "#E4D057",
        secondary: "#FFFFFF",
      },
      success: {
        main: "#0FC764",
      },
      error: {
        main: "#FF525C",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: "'Righteous', cursive",
      // fontFamily: "'Roboto', sans-serif;",
    },
  });

  return (
    <Web3ChainProvider>
      <Web3ContextProvider>
        <ThemeProvider theme={responsiveFontSizes(theme)}>
          <Main />
        </ThemeProvider>
      </Web3ContextProvider>
    </Web3ChainProvider>
  );
};

export default App;
