import {
  Box,
  TextField,
  useTheme,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import InputDataField from "../../../common/InputDataField";
import ShareSocial from "../../../common/ShareSocial";
import ShareIcon from "@mui/icons-material/Share";
import { useState } from "react";

interface SuccessProp {
  nameOfReferee: string;
  refereeWalletAddress: string;
  amountStake: string;
  refererWalletAddres: string;
}

const ReferralSummary: React.FC<SuccessProp> = (props: SuccessProp) => {
  const {
    nameOfReferee,
    refereeWalletAddress,
    amountStake,
    refererWalletAddres,
  } = props;

  const theme = useTheme();

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const toggleClose = () => {
    setOpenDialog(false);
  };
  let referralLinkValidity: string = "Link is valid for 7 days";
  return (
    <>
      <Box
        sx={{
          display: {
            xs: "block",
            lg: "flex",
          },
          p: "2px 0 2px 0",
        }}
      >
        <InputDataField
          fieldName={"Name Of Referee"}
          fieldData={nameOfReferee}
          sxFieldName={{ color: theme.palette.text.primary }}
          sxFieldData={{
            fontSize: {
              xs: "12px",
              color: theme.palette.text.secondary,
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            lg: "flex",
          },
          p: "2px 0 2px 0",
        }}
      >
        <InputDataField
          fieldName={"Referee’s Wallet Address"}
          fieldData={refereeWalletAddress}
          sxFieldName={{ color: theme.palette.text.primary }}
          sxFieldData={{
            fontSize: {
              xs: "12px",
            },
            color: theme.palette.text.secondary,
          }}
        />
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            lg: "flex",
          },
          p: "2px 0 2px 0",
        }}
      >
        <InputDataField
          fieldName={"Amount to Stake"}
          fieldData={amountStake}
          sxFieldName={{ color: theme.palette.text.primary }}
          sxFieldData={{
            fontSize: {
              xs: "12px",
            },
            color: theme.palette.text.secondary,
          }}
        />
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            lg: "flex",
          },
          p: "2px 0 2px 0",
        }}
      >
        <InputDataField
          fieldName={"Referrer’s Wallet Address"}
          fieldData={refererWalletAddres}
          sxFieldName={{ color: theme.palette.text.primary }}
          sxFieldData={{
            fontSize: {
              xs: "12px",
            },
            color: theme.palette.text.secondary,
          }}
        />
      </Box>

      <Box
        sx={{
          display: {
            xs: "block",
            lg: "block",
          },
          p: "15px 0 15px 0",
        }}
      >
        <Typography
          variant="subtitle2"
          color="initial"
          sx={{ color: theme.palette.text.primary }}
        >
          Referral Link
        </Typography>
        <TextField
          fullWidth
          id="referralLink"
          name="referralLink"
          disabled
          value={"https://client.uniteddao.io/invite?code=2548asi1212331"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  aria-label="copy-link"
                  sx={{
                    "& .MuiButton-text": {
                      color: theme.palette.text.primary,
                    },
                    "& .MuiBox-root": { color: theme.palette.text.primary },
                  }}
                  onClick={() => setOpenDialog(true)}
                >
                  <ShareIcon sx={{ color: theme.palette.text.primary }} />
                  <Box component="p">Share</Box>
                </Button>
              </InputAdornment>
            ),
          }}
          sx={{
            background: "#374575",
            borderRadius: "8px",
            "& .MuiInputLabel-root": {
              color: theme.palette.text.secondary,
            },
            "& .Mui-disabled": {
              color: theme.palette.text.secondary,
              WebkitTextFillColor: theme.palette.text.secondary + "!important",
            },
          }}
        />

        <Typography
          variant="subtitle2"
          color="initial"
          sx={{
            textAlign: "left",
            color: theme.palette.text.primary,
            fontSize: "10px",
          }}
        >
          {referralLinkValidity}
        </Typography>
      </Box>
      <ShareSocial open={openDialog} toggleClose={toggleClose} />
    </>
  );
};

export default ReferralSummary;
