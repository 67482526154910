import BondStake from "../components/UI/Summary/BondStake";

const Summary: React.FC = () => {
  return (
    <>
      <BondStake />
    </>
  );
};

export default Summary;
