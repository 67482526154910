import { Box, Card, Divider, Typography, useTheme } from "@mui/material";

interface LandingProps {
  title: string;
  divider: boolean;
  button?: React.ReactNode;
  textDisplay?: string;
  description?: string;
  subDesciption?: string;
  invitationError?: string | null;
}

const cardStyles = {
  justifyContent: "space-between",
  borderRadius: 2,
  padding: 3,
};

const Landing: React.FC<LandingProps> = (props: LandingProps) => {
  const {
    title,
    divider,
    button,
    textDisplay,
    description,
    subDesciption,
    invitationError,
  } = props;
  const theme = useTheme();

  return (
    <Box sx={{ maxHeight: "calc(100% - 60px)", display: "flex" }}>
      <Card
        sx={{
          ...cardStyles,
          width: "100%",
          height: { lg: "calc(50% - 20px)", xs: "fit-content" },
          marginBottom: 3,
        }}
      >
        <Box
          sx={{
            display: { xs: "block", md: "flex", lg: "flex" },
            justifyContent: "center",
            textAlign: {
              xs: "center",
            },
          }}
        >
          <Typography
            variant="subtitle1"
            color="initial"
            sx={{ color: theme.palette.text.primary }}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          {divider ? (
            <Divider
              light
              sx={{
                backgroundColor: theme.palette.text.primary,
              }}
            />
          ) : null}
        </Box>
        {invitationError ? (
          <Box
            sx={{
              textAlign: "center",
              position: { lg: "relative" },
              top: { lg: 15 },
              pt: 2,
            }}
          >
            <Typography
              variant="subtitle1"
              color="initial"
              sx={{
                color: theme.palette.error.main,
                fontSize: {
                  xs: "0.75rem",
                  lg: "1rem",
                },
              }}
            >
              {invitationError}
            </Typography>
          </Box>
        ) : null}
        {description ? (
          <Box
            sx={{
              textAlign: "center",
              p: {
                xs: 1,
                lg: 3,
              },
            }}
          >
            <Typography
              variant="subtitle1"
              color="initial"
              sx={{
                color: theme.palette.text.primary,
                fontSize: {
                  xs: "0.75rem",
                  lg: "1rem",
                },
              }}
            >
              {description}
            </Typography>
          </Box>
        ) : null}
        <Box
          sx={{
            textAlign: "center",
            pl: {
              xs: 3,
              lg: 3,
            },
            pr: {
              xs: 3,
              lg: 3,
            },
          }}
        >
          {textDisplay ? (
            <Typography
              sx={{
                fontSize: {
                  xs: "0.5rem",
                  lg: "1rem",
                },
                wordWrap: "break-word",
                border: "1px solid" + theme.palette.text.primary,
                background: "transparent",
                borderRadius: "8px",
                pt: 1,
                pb: 1,
                "& .MuiInputLabel-root": {
                  color: theme.palette.text.secondary,
                },
              }}
            >
              {textDisplay}
            </Typography>
          ) : null}
        </Box>
        {button ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: {
                xs: 2,
                lg: 4,
              },
            }}
          >
            {button}
          </Box>
        ) : null}

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="subtitle2"
            color="initial"
            sx={{
              color: theme.palette.text.primary,
              fontSize: {
                xs: "8px",
                lg: "10px",
              },
            }}
          >
            {subDesciption}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default Landing;
